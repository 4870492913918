//Assign Users
.assign-user{
        width: 280px;
        position: absolute;
        right: 0;
        z-index: 3;
        @include boxshadow;
        @include borderRadius_15;
        transition: all 0.3s ease-in-out;
        -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            
    h2{
        font-size: 16px;
        font-weight: 500;
        padding: 15px 20px;
        margin: 0;
    }
    .users-list{
        padding: 15px;
        margin: 0 20px;
        background: $card-footer-bg;
        @include borderRadius_10;
        overflow: auto;
        max-height: 160px;
        position: relative;
        .form-check{
            margin: 5px 0;
            .form-check-label{
                padding-left: 10px;
                font-size: 1rem;
                text-transform: capitalize;
            }
        }
        .accordion-item{
            border: 0;
            background: $card-footer-bg;
        }
    }
    .filter-btn{
        padding: 20px;
         @include justify-between;
        .btn-common{
            min-width: 110px;
            font-size: 13px;
        }

    }
    .card-body{
        padding: 0;
    }
}

//Select FIlters
.assign-user {
   &.select-group {
        width: 330px;
        .users-list {
            overflow: visible;
            height: auto;
            padding: 0;
            background: transparent;
            max-height: max-content;
            [class*="-control"] {
                background: $card-footer-bg;
                margin-bottom: 10px;
            }
            [class*="-menu"] {
                [class*="-MenuList"]{
                    background: $card-footer-bg;
                }
            }
        }
        .filter-btn {
            justify-content: flex-start;

            .primary-button {
                margin-left: 8px;
            }
        }
    }
}

// Filters ICon
.doc-filters {
    margin: 0 3px;
    i {
        color: $primary-color;
        font-size: 23px;
        cursor: pointer;
    }

}
.open-filter{
  transition: all 0.3s ease-in-out;
}
@-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-30px);
              transform: translateY(-30px);
    }
  }
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-30px);
              transform: translateY(-30px);
    }
  }
//date range picker
.date-picker-drop {
    &.assign-user {
        &.select-group {
            // width: max-content;
            margin-top: 50px;
        }
    }
}

.rs-picker-daterange-menu {
    left: auto !important;
    right: 25px !important;
    top: 175px !important;
    transition: all 0.3s ease-in-out;
    -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
