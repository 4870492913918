//Dashboard

// Placeholder image
.user-placeholder {
    height: calc(100vh - 155px);
    .user-placeholder__image{
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 100%;
        .primary-button{
            margin-top: 50px;
        }
    }
}

//Credit Style
.credit-bg {
    padding: 40px; 
    background: $primary-color url('../../../images/icons/credit-bg.svg') no-repeat center;
    background-size: cover;
    @include borderRadius_15;
    margin-bottom: 30px;
    .credit-item {
        @include flex-center-vert;
        margin-right: 25px;
        padding-right: 25px;
        .credit-icon {
            @include box(65px, 65px);
            @include borderRadius_50;
            background: $white;
            @include flex-center;
        }
        .credit-left {
            margin-left: 20px;

        }
    }
    .credit-left {
        h6 {
            @include FontMedium;
            color: $white;
            margin: 0;
        }

        h1 {
            font-size: $xxxmedium;
            font-weight: $bold;
            color: $white;
            margin: 0;

            span {
                font-size: $xxxlarge;
                font-weight: $regular;
            }
        }
    }
    .credit-part{
        @include flex-center-vert;
    }
    .progress-group{
        @include justify-between;
        .progress {
            height: 12px;
            @include borderRadius_30;
            margin: 15px 0 8px 0px;
        }
        .progress-bar{
            background-color: $progressbar-bg !important;
        }
        .prog-val{
            @include justify-between;
        }
        .credit-btn{
            @include flex-center-vert;
            button{
                background: $white;
                color: $primary-color;
                border: 0;
                font-weight: $medium;
                @include borderRadius_30;
                padding: 10px 20px;
                margin-right: 15px;
            }
            .bx{
                color: $white;
                font-size: $xxlarge;
                cursor: pointer;
            }
        }
        .credit-left{
            width: 70%;
        }
    }
}
// Users
.users {
    .card-header {
        @include justify-between;
        padding-bottom: 10px;
        margin-bottom: 10px;
        h1{
            padding: 0;
        }
        span {
            color: $primary-color;
            text-decoration: underline;
            cursor: pointer;
            font-size: $fs-medium;
        }
    }
    .card-body {
        padding: 30px;
        .user-icon{
            @include box(65px, 65px);
            @include borderRadius_50;
            margin-right: 15px;
            background: $useractive-bg;
            @include  flex-center;  
            
        }
        .user-info {
            @include flex-center-vert;
            border-bottom: 1px solid $border-color-line;
            padding-bottom: 40px;
            &:last-of-type{
                border-bottom: none;
                padding-bottom: 0;
            }
            &.in-active{
                padding-top: 40px;
                .user-icon{
                    background: $userinactive-bg;
                }
                .users-status{
                    h2{
                        color: $nobel-color;
                    }
                }
            }
            .users-status {
                 h4 {
                    color: $dark-grey;
                    font-size: $fs-medium;
                    font-weight: $medium;
                    margin: 0;
                }
                h2 {
                    font-size: $xxxxlarge;
                    color: $primary-color;
                    font-weight: $bold;
                    margin: 0;
                }
            }
        }
    }
}
.invoice-header {
    @include justify-between;
    margin-bottom: 30px;
    h1 {
        padding-bottom: 0
    }
    .dash-filters {
        @include flex-center-vert;
        p {
            font-weight: $medium;
            color: $dark-grey;
            margin: 0;
        }
        .drop-down {
            width: 170px;
            margin-left: 20px;
        }
    }
}
//Status Card
.status-group {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
    min-height: 145px;
    .card {
        padding: 30px;
        @include borderRadius_15;
        cursor: pointer;
        h2 {
            @include FontMedium;
            margin: 0;
        }
        h1 {
            font-weight: $bold;
            color: $upload-color;
            margin: 0;
            font-size: $xxxxlarge;
            
        }
        .call-to-action {
            position: absolute;
            top: 10px;
            right: 20px;
            @include box(20px, 20px);
            @include borderRadius_30;
            text-align: center;
            transition: all 0.5s ease-in-out;
             img{
                text-align: center;
                transition: all 0.5s ease-in-out;
             }
        }
        &:nth-child(2) h1{
            color: $review-color;
        }
        &:nth-child(3) h1{
            color: $approved-color;
        }
        &:nth-child(4) h1{
            color: $rejected-color;
        }
        &:nth-child(5) h1{
            color: $skipped-color;
        }
        &:hover{
            .call-to-action {
                background: $primary-color;
                transition: all 0.5s ease-in-out;
                img{
                    filter: brightness(10);
                    transition: all 0.5s ease-in-out;
                }
            }
        }
    }
}

//Chart
.chart-section {
    .card-body {
        padding: 30px;
    }
}
.chats-bg {
    background: $card-footer-bg;
    @include borderRadius_15;
    padding: 30px;
    height: 455px;
    h4{
        font-size: $large;
        font-weight: $medium;
        color: $dark-grey;
    }
    .apexcharts-legend-text{
        color: $dark-grey !important;
        font-weight: 500 !important;
        font-size: 14px !important;
    }
}
// Recent Active
.table-recent{
    .table-filter-section{
        display:  none;
    }
}
.users {
    .recent-active {
        .card-body {
             padding: 0 !important;
         }
     }
     .table-responsive{
        height: 215px;
     }
 }
.chart-group{
    display: grid;
    grid-template-columns: 28% 70%;
    grid-gap:30px;
}
 @media  screen and (max-width: 1540px) {
    .status-group{
        grid-gap:20px;
    }
    .chart-group{
        // @extend .status-group;
        grid-gap:20px;
    }
    .credit-bg {
        .credit-item {
            margin-right: 10px;
            padding-right: 10px;
            .credit-icon{
                @include box(50px, 50px);
            }
        }
        .credit-part{
            justify-content: space-between;
        }
    }
}

// file upload popup
.uploading-files{
    .file-uploader {
        border: 2px dashed $primary-color;
        @include borderRadius_10;
        height: 150px;
        padding: 20px;
        text-align: center;
        cursor: pointer;
        position: relative;
        background: $white;
        &.is-invalid{
            background: $error-bg;
        }

        input[type="file"] {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
          }

          h5 {
            margin-bottom: 5px;
          }

          p {
            font-size: 12px;
          }
    }

  #file-previews{
    .col-auto{
        background: $white;
        padding: 15px;
        @include borderRadius_10;
        @include flex-center-vert;
        position: relative;
        p{
            margin: 0;
            color: $dark-grey;
            font-size: $lite;
            padding-left: 10px;
            font-weight: $medium;
            padding-right: 20px;
        }
        .bx{
            color: $primary-color;
            font-size: $xxxlarge;
        }
        .file-close{
            position: absolute;
            right: 15px;
            top: 15px;
            padding-right: 0px;
            cursor: pointer;
            .bx{
            color: $primary-color;
            font-size: $xxxlarge;
            }
        }
    }
  }
}
.upload-container{
    .card{
        @include borderRadius_15;
    }
   .card-body{
      
   }

}

.bar-chats {
    .apexcharts-legend.apx-legend-position-bottom {
        justify-content: space-between !important;
    }
}
.pie-chats{
    .apexcharts-legend.apx-legend-position-bottom {
        justify-content: space-between !important;
       
    }
    .apexcharts-legend-series{
        width: 40% !important;
        margin: 5px 0 !important;
    }
}
//   .file-uploader ul {
//     list-style: none;
//     padding: 0;
//   }
  
//   .file-uploader li {
//     margin: 5px 0;
//   }
[data-layout-mode="dark"] {
    .uploading-files {
        .file-uploader{
            background: $sidebar-dark-bg;
            border: 2px dashed #ffffff7a;
        }
        #file-previews{
            .col-auto{
                background: $sidebar-dark-bg;
                p{
                    color: $white;
                } 
            }
        } 
       
    }
    
    .doc-modal{
        .drop-down {
            [class*="-menu"]{
                background: $sidebar-dark-bg !important; 
            }
           [class*="-control"]{
            background: $sidebar-dark-bg !important; 
           }
        }
    }
    .upload-container{
        .card{
            background: $dark-back-bg;
        }
       
    }
}
.apexcharts-tooltip span {
    color: #ffffff;
    font-weight: 500;
    text-transform: capitalize;
 
}
.apexcharts-tooltip-series-group{
    padding: 2px 15px !important;
}
.apexcharts-xaxis text{
    fill: $dark-grey;
}

@media screen and (max-width: 1366px){
    .credit-bg {
        padding: 30px;
        .credit-left {
            h1{
                font-size: 28px;
            }
        }
    }
}
@media screen and (max-width: 992px){
    .chart-group {
           grid-template-columns: 100%;
    }
    .status-group {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (max-width: 630px){
    .status-group {
        grid-template-columns: repeat(1, 1fr);
    }
}