// 
// Page-title
// 

.page-title-box {
    padding-bottom: $grid-gutter-width;

    .breadcrumb {
        background-color: transparent;
        padding: 0;
    }

    h4 {
        text-transform: capitalize;
        display: flex;
        justify-content: center;
        align-items: center;
        .bx{
            width: 35px;
            height: 35px;
            color: $white;
            background: $primary-color;
            @include borderRadius_30;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            font-size: 23px;
        }
    }
}
.review-breadcrumb {
    padding: 25px 0 0 25px;
    background: $userinactive-bg;
    position: relative;
    z-index: 1;

}