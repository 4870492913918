//
// _buttons.scss
//
button:disabled {
   opacity: 0.4;
}

button,
a {
    outline: none !important;
}

// Rounded Buttons
.btn-rounded {
    border-radius: 30px;
}

// example 1

.btn-group-example {
    .btn {
        position: relative;

        &:first-child {
            &::before {
                display: none;
            }
        }

        &:before {
            content: "OR";
            position: absolute;
            font-size: 10px;
            width: 24px;
            height: 24px;
            line-height: 24px;
            border-radius: 50%;
            background-color: $gray-600;
            color: $light;
            border: 1px solid $white;
            left: -12px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
        }
    }
}

// Example 2

.btn-label {
    position: relative;
    padding-left: 44px;
    .label-icon {
        position: absolute;
        width: 32px;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba($white, 0.1);
        border-right: 1px solid rgba($white, 0.1);
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &.btn-light {
        .label-icon {
            background-color: rgba($dark, 0.1);
            border-right: 1px solid rgba($dark, 0.2);
        }
    }
}


.btn-group-vertical{
    label {
        margin-bottom: 0px;
    }
}


//
// Soft Buttons
// 

@mixin button-variant-soft($bg) {
    color: $bg;
    background-color: rgba($bg, 0.1);
    border-color: transparent;
    transition: all 0.5s ease;
    &:hover,
    &:focus,
    &:active {
        color: $white;
        background-color: $bg;
        border-color: transparent;
    }
}

@each $color,
$value in $theme-colors {
    .btn-soft-#{$color} {
        @include button-variant-soft($value);
    }
}

.like-btn {
    &.active {
        .bx-heart {
            color: $danger;
            &:before {
                content: "\ec8f";
            }
        }
    }
}
.btn-common{
    @include borderRadius_30;
    font-size: 16px;
    font-weight: 500;
    line-height: 14px;  
    min-width: 122px;
    height: 40px; 
    @include flex-center;
    text-transform: capitalize;
    img{
        margin-right: 10px;
    }
    &:hover{
       img{
        filter: brightness(10);
       }
    }
}
.primary-button {
    background: $primary-color;
    padding: 5px 20px;
    border: 1px solid $primary-color;
    color: $white;

    &:hover {
        border: 1px solid $primary-color !important;
        background: transparent !important;
        color: $primary-color !important;
    }

    &:active {
        border: 1px solid $primary-color !important;
        background-color: transparent !important;
        color: $primary-color !important;
    }
}

label {
    &.primary-button {
        &.small {
            height: 35px;
        }
        
        &:hover {
            background:$primary-color !important;
            color: $white !important;
        }
    }
}

.secountry-btn {
    padding: 5px 8px;
    border: 1px solid $primary-color;
    color: $primary-color;
    background: transparent;
    outline: none;
    box-shadow: none !important;

    &:hover {
        border: 1px solid $primary-color !important;
        background: $primary-color !important;
        color: $white !important;
    }

    &:active {
        border: 1px solid $primary-color !important;
        background: $primary-color !important;
        color: $white !important;
    }
}

.floting-btn{
    position: relative;
    background: $primary-color;
    width: 43px;
    height: 43px;
    @include borderRadius_30;
    @include flex-center;
    cursor: pointer;
}

//File Upload
.file-upload-btn {
    margin-left: 30px;

    .secountry-btn {
        padding: 5px 25px;
    }
}

.table-footer .select-download {
    .file-upload-btn {
        margin-left: 30px;

        .secountry-btn {
            padding: 5px 25px;

            span {
                color: inherit;
                font-weight: $medium;
            }

        }
    }
}
.train-doc{
    .btn-common{
        &.secountry-btn{
            min-width: 140px !important;
        }
    }
}