// authentication.scss
.auth-logo {
    display: none;
    .auth-logo-dark {
        display: $display-block;
    }

    .auth-logo-light {
        display: $display-none;
    }
}

.auth-body-bg {
    background-color: $card-bg;
}

.auth-content{
    h4{
        font-size: $xxxmedium;
        color: $dark-grey;
        font-weight: $medium;
    }
    p{
        font-size: $fs-medium;
        color: $dark-grey;
    }
}
// auth-pass-inputgroup
.auth-pass-inputgroup {
    input[type="input"]+.btn .mdi-eye-outline {
        &:before {
            content: "\F06D1";
        }
    }
}

// authentication full page

.auth-full-bg {
    background-color: rgba($primary, 0.25);
    display: flex;

    @media (min-width: 1200px) {
        height: 100vh;
    }


    &::before {
        content: "";
        position: absolute;
        width: 300px;
        height: 300px;
        border-radius: 50%;
    }

    .bg-overlay {
        // background: url("../../../images/bg-auth-overlay.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.auth-full-page-content {
    display: flex;

    @media (min-width: 1200px) {
        min-height: 100vh;
    }
}

.account-pages {
    background: $white;
    height: 100vh;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    .login-bg {
        background: $primary-color url('../../../images/svgimages/login-bg.svg') top center no-repeat;
        background-size: cover;
        height: 100%;
        padding: 100px;
        position: relative;

        .welcome-content {
            position: absolute;
            bottom: 100px;

            h1 {
                color: $white;
                font-size: $xxxxlarge;
                font-weight: $semibold;
            }

            p {
                color: $white;
                font-size: $xlarge;
                max-width: 450px;
                margin: 0;
            }
        }
    }

    .container {
        overflow: hidden;
    }
    .error-img {
        text-align: center;

        img {
            max-width: 460px;
        }
    }
    .d-grid {
        display: grid !important;
        width: 70%;
        margin: 0 auto;
    }
}

.welcome-form{
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    form{
        padding-top: 20px;
    }
    .form-control{
        border: 1px solid $input-border;
        // margin-bottom: 20px;
    }
    .show-password{
        display: flex;
        justify-content: space-between;
        #password-addon{
            background: transparent;
        }
        .mdi{
            font-size: $fs-medium;
            color: $dark-grey;
            opacity: 0.6;
        }
    }
    .primary-button{
        margin-top: 30px;
    }
    .forgot-password{
       a{
        color: $dark-grey;
        text-decoration: underline !important;
       }
    }
}
//Social LOgin
.or-option {
    position: relative;
    margin: 20px 0;
    padding: 20px 0;

    .line {
        width: 100%;
        height: 1px;
        background: $border-color-line;
    }

    p {
        margin: 0;
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 5px;
        bottom: 0;
        display: inline-block;
        padding: 5px;

        span {
            padding: 5px;
            background: $white;
        }

    }
}
.auth-pass-inputgroup{
    .form-control{
        &::placeholder{
            position: relative;
            top: 3px;
        }
    }
}

.social-login {
    display: flex;
    justify-content: space-evenly;
  
    button{
        padding: 12px 35px;
        @include borderRadius_30;
        img{
            margin-right: 8px;
        }
     
    }
}
.profile-screen {

    .user-info {
        .card {
            height: 100%;
        }
    }
}
.logo-lg{
    img{
        width: 60%;
    }
}
.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating){
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
@media screen and (max-width: 1440px) {
    .account-pages{
        .login-bg{
            padding: 80px;
            .welcome-content{
                 bottom: 80px;
            }
        }
    }
}
@media screen and (max-width: 480px) {
    .account-pages {
            .auth-card {
    
                .background-auth-page {
                    .auth-head {
                        position: relative;
    
                        .auth-heading {
                            width: 100%;
                            z-index: 1;
                        }
    
                        .logo-img {
                            width: 100%;
                            position: absolute;
                        }
                    }
                }
            }
        }
}