.authLoader {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0px;
    padding: 0px;
    background: $primary-color;
    padding: 5px 20px;
    height: 40px;
    border-radius: 30px;
    width: 100%; 
}

.authLoader span {
    vertical-align: middle;
    border-radius: 100%;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 3px 2px;
    -webkit-animation: authLoader 0.8s linear infinite alternate;
    animation: authLoader 0.8s linear infinite alternate;
}

.authLoader span:nth-child(1) {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
    background: rgba(248, 248, 248, 0.4);
}

.authLoader span:nth-child(2) {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
    background: rgba(248, 248, 248, 0.8);
}

.authLoader span:nth-child(3) {
    -webkit-animation-delay: -0.26666s;
    animation-delay: -0.26666s;
    background: rgba(248, 248, 248, 1);
}

.authLoader span:nth-child(4) {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
    background: rgba(248, 248, 248, 0.8);

}

.authLoader span:nth-child(5) {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
    background: rgba(248, 248, 248, 0.4);
}

@keyframes authLoader {
    from {
        transform: scale(0, 0);
    }

    to {
        transform: scale(1, 1);
    }
}

@-webkit-keyframes authLoader {
    from {
        -webkit-transform: scale(0, 0);
    }

    to {
        -webkit-transform: scale(1, 1);
    }
}
.account-pages{
    .authLoader{
        margin-top: 27px;
    }
}
.modal{
    .authLoader{
        padding: 5px 20px;
        max-width: 130px; 
    }
}
.action-btn-loader {
    outline: none;
    .authLoader{
        padding: 5px 20px;
        max-width: 130px; 
    }
}