.file-upload{
    position: relative;
    padding-bottom: 25px;
    display: inline-block;
    .new-file{
        display: inline-block;
        color: $primary-color;
        font-size: $large;
        font-weight: $semibold;
        min-width: 115px;
        padding: 8px 20px;
        background: $useractive-bg;
        @include borderRadius_30;
        @include flex-center-vert;
        margin: 0;
        cursor: pointer;
        .bx-plus{
            color: $primary-color;
            font-size: $large;
            font-weight: $semibold;
            margin-right: 5px;
        }
    }
}

// DropDown Style
.dropdown-group{
    width: 220px;
    background: $white;
    @include borderRadius_15;
    @include boxshadow;
    position: absolute;
    z-index: 3;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: DropDownSlide;
    animation-name: DropDownSlide;
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175);
    ul{
        display: block !important;
        padding: 0;
        &.mm-collapse{
            display: block !important;
            padding: 0;
        }
        li{
            padding: 8px 20px;
            cursor: pointer;
            font-size: $fs-medium;
            font-weight: $regular;
            color: $dark-grey;
            border-bottom: 1px solid $border-color-line;
            position: relative;
            text-align: left;
            &:last-child{
                border-bottom: 0;
            }
            &:hover{
                background: $userinactive-bg;
            }
        }
    }
}

@keyframes DropDownSlide {
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    0% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
    }
}
.drop-submenu{
    position: absolute;
    width: 220px;
    top: 0;
    right: -230px;
    background: $white;
    @include borderRadius_15;
    @include boxshadow;
}
.folder-upload-icon{
    .bx{
        position: absolute;
        right: 8px;
        top: 8px;
        font-size: 25px;
    }
}
.vertical-collpsed {
    .vertical-menu {
        #sidebar-menu  {
            .file-upload{
               .new-file{
                min-width: 40px;
                padding: 10px 10px;
                justify-content: center;
                span{
                    display: none;
                }
                .bx {
                    margin-right: 0;
                }
               }
            }
            .new-btn{
                display: block;
                text-align: center;
            }
        }
    }
}