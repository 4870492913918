.error-field {
    padding-left: 18px;
    position: relative;
    width: 100%;
    margin-top: 0.25rem !important;
    font-size: 13px !important;
    color: $error-color;
    font-weight: 400 !important;

    &::before {
        content: '';
        position: absolute;
        top: 2px;
        left: 0;
        width: 14px;
        height: 14px;
        background: url('../../../images/svg/alert-circle.svg');
        background-size: cover;
    }
}

.settings {
    .tab-section {
        padding-top: 0px;

        .nav-tabs-custom {
            .nav {
                height: 70px;

                .nav-item {
                    @include flex-center-vert;

                    .tab-item {
                        height: 100%;
                        padding: 0;
                        border-bottom: 2px solid transparent;

                        &.active {
                            border-bottom: 2px solid $primary-color;
                        }
                    }
                }
            }
        }
    }

    .accountTab {
        .form-control {
            background-color: $snow-drift;
        }

        .lableAction {
            @include justify-between;

            button {
                border: none;
                background: transparent;
                font-size: $lite;
                text-decoration: underline;
                color: $primary-color;
            }
        }
    }

    .userList {
        p {
            margin: 0;
            font-size: $fs-medium;
            font-weight: $medium;
            text-transform: capitalize;
        }

        .accordion-item {
            border: 0px;
            background-color: $snow-drift;
            margin-bottom: 14px;
            border-radius: 15px;

            .accordion-header {
                .accordion-button {
                    padding: 25px;
                    border-bottom: 1px solid $border-bg;
                    background: transparent;
                    color: $dark-grey;

                    &.collapsed {
                        border-bottom: 0px;

                        &:after {
                            transform: rotate(180deg);
                        }
                    }

                    &:after {
                        background-image: url("../../../images/icons/arrow.svg");
                        background-size: 16px;
                        width: 20px;
                        transform: rotate(0deg);
                    }

                    .row {
                        border: 0;
                        @include flex-center-vert;
                    }

                    .action {
                        .dropdown {
                            &.show {
                                .backdrop {
                                    position: fixed;
                                    height: 100vh;
                                    width: 100vw;
                                    top: 0;
                                    right: 0;
                                    z-index: 10;
                                    background: rgba(0, 0, 0, 0.15);
                                }
                            }
                        }

                        .btn:is(:hover, :focus, :active) {
                            outline: none !important;
                            border: 0;
                        }

                        .action-btn {
                            font-size: $xxxlarge;
                            position: relative;
                            top: -5px;
                        }
                    }

                    .userInfo {
                        @include flex-center-vert;

                        .avather {
                            width: 38px;
                            height: 38px;
                            border-radius: 50%;
                            font-size: $fs-medium;
                            font-weight: $medium;
                            background: $avather-bg;
                            color: $avather-color;
                            @include flex-center;
                            margin-right: 15px;

                            .span {
                                font-size: $lite;
                                font-weight: $medium;
                            }
                        }
                    }

                    .status {
                        span {
                            border-radius: 25px;
                            padding: 6px 15px;
                            font-size: $lite;
                            display: inline-block;
                            min-width: 95px;
                            text-align: center;
                        }

                        &.active {
                            span {
                                color: $status-app-text;
                                background: $status-approved;
                            }
                        }

                        &.deactive {
                            span {
                                color: $status-reg-text;
                                background: $status-rejected;
                            }
                        }
                    }
                }
            }

            .accordion-body {
                padding: 25px 30px;

                .model-access {
                    &.form-control{
                        padding: 18px;
                        background: $white;
                        @include borderRadius_10;
                        overflow-y: auto;
                        max-height: 147px;
                        height: auto;
                        &.is-invalid{
                            background: #f9ebeb;
                        }
                    }

                }

                .password {
                    input {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }

                    .primary-button {
                        @include borderRadius_10;
                        padding: 18px 20px;
                        height: 52px;
                        width: 52px;
                    }
                }

            }
        }
        .dropdown-menu-end {
            @include borderRadius_10;
            padding: 0;
            overflow: hidden;
        }
    }
}

//account settings actions
.accountTab {
    .action {
        text-align: end;
        color: red;
        padding: 10px;

        button {
            background: transparent;
            border-bottom: 1px solid red;
            padding: 0;
        }
    }
}

[data-layout-mode="dark"] {
    .settings {
        .userList {
            .accordion-item{
                background: $dark-back-bg;
                .accordion-body {
                    .model-access{
                        background: $dark-input-file; 
                    }
                }
                .accordion-button .action .action-btn{
                    color: $white;
                }
                .accordion-button{
                    border-bottom: 1px solid $dark-input-file;
                    &:after{
                        filter: brightness(10);
                    }
                }
            }
            .form-control{
                background: $dark-input-file !important; 
            }
            p{
                color: $white;
            }
            .details{
                span{
                    color: $white; 
                }
            }
            .drop-down [class*="-control"] {
                background: $dark-input-file !important;
            }
        }
    }
}