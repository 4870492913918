// 
// _card.scss
// 

.card {
  margin-bottom: $grid-gutter-width;
  // box-shadow: $box-shadow;
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;  
}
.page-content {
  .card{
    @include borderRadius_15;
  }
  .card-body {
    background: $white;
    @include borderRadius_15;
  }
}
// new style
body[data-layout-mode="dark"] {
  .page-content {
    .card-body {
      background: $dark-input-file;
     
    }
    .status-group{
      .card {
        background: $dark-back-bg;
        h2{
          color: $white;
        }
     }
    }
     .chats-bg{
      background: $dark-back-bg;
      h4{
        color: $white; 
      }
    }
  }
}