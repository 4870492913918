//
// Contacts.scss
//
.contact-links {
  a {
    color: var(--#{$prefix}body-color);
  }
}


.search-box {
  .form-control {
    border-radius: 10px;
    padding-left: 40px;
    border: none;
  }
}

.search-box {
  .search-icon {
    font-size: 16px;
    left: 13px;
    line-height: 38px;
    position: absolute;
    top: 10px;
  }
}

.dots-admin-list {
  position: absolute;
  right: 5px;
  top: 5px;
}

.option-btn,
.option-btn:hover {
  background: transparent !important;
  border: none;
  color: #5e5a5a;
  font-size: 20px;
}

.admin-card-footer {
  display: flex;
  justify-content: space-around;
  align-items: center;

  i {
    font-size: 20px;
    color: $icon-grey;
  }

  .bx-edit-alt {
    color: $primary-color;
  }
}

.content {
  .avatar-img {
    height: auto;
    width: auto !important;
    display: flex;
    justify-content: center;

    .avatar-txt {
      height: 100px;
      width: 100px;
      font-size: 45px;
    }
  }
}

.role {
  .select-search-input {
    background: $error-bg;
    border: 1px solid $error-color !important;
    padding-right: calc(1.5em + 0.94rem);
    // background-image: url('./../../../images/svg/alert-circle.svg');
    // background-repeat: no-repeat;
    // background-position: right calc(0.375em + 0.235rem) center;
    // background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
  }

  .select-down-arrow {
    i {
      color: $primary-color;
    }
  }

}

.role-error {
  &.invalid-feedback {
    display: block;
  }
}

#sidebar-menu {
  ul {
    li {
      a {
        display: flex;
        align-items: center;
        &.inactive {
          &.active {
            color: $white;
            background: $primary-color;
            @include borderRadius_15;

            img {
              filter: brightness(5);
            }
          }
        }

        span {
          font-weight: 500;
          padding-left: 13px;
          transition: 0.2s ease-in-out;
        }
      }
    }
  }
}

tbody {
  .collapse-heading {
    display: flex;
    text-align: center;

    h5 {
      width: 100%;
      margin-bottom: 0;
    }
  }

  .collapse-data {
    display: flex;
    text-align: center;

    p {
      width: 100%;
      margin-bottom: 10px;
    }

    &:last-child {
      padding-bottom: 10px;
    }
  }
}


.table tr td {
  padding: 8px 10px;
  vertical-align: middle;
  text-transform: capitalize;
}

.btn-group {
  width: 100%;

  .btn {
    &.select {
      background: $gray-600;
      color: #ffffff;
    }
  }
}


.react-datepicker-popper {
  z-index: 2;
}

.select-search-container {
  .select-search-options {
    margin-bottom: 0;
  }

  .select-search-option {
    height: auto;
    padding: 3% 20px;
  }
}

.main-content {
  overflow: unset;
}

.custom-header-css thead tr th div:last-child {
  min-height: auto !important;
}

// .custom-header-css.recent-quotes-table thead tr th div {
//   &:last-child {
//     display: none;
//   }
// }

.passwordChange-fields {
  input {
    padding-right: 34px;
  }

  label {
    position: relative;
    width: 100%;

    span {
      position: absolute;
      right: 12px;
      top: 42px;
      color: black;
    }
  }

  // input {
  //   width: 95%;
  // }

}

.ellipsis-text {
  white-space: nowrap;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-muted {
  &.ellipsis-text {
    margin: 0 auto 1rem;
  }
}

//autho page


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $white inset !important;
  -webkit-text-fill-color: $battleship-grey;
}
.custom-input{
  input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $colorsnow inset !important;
  -webkit-text-fill-color: $battleship-grey;
}
}

//primary-color
.primary-color {
  color: $primary-color;
}



