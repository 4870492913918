.activity-added{
    p{
        margin: 0;
        color: $dark-grey;
        span{
            font-size: 12px;
            color: $date-text;
        }
    }
}
.align-flex-end{
    display: flex;
    justify-content: flex-end;
}
.document-type {
    width: 70%;

    .file-status {
        padding: 3px 10px;
        margin: 0;
        border-radius: 6px;
        text-align: center;
        min-width: 115px;
        display: inline-block;
        font-size: 13px;

        &.document {
            background: $documentbg;
            color: $documenttext;
        }

        &.api {
            background: $apibg;
            color: $apitext;
        }

        &.user {
            background: $usersbg;
            color: $userstext;
        }

        &.settings {
            background: $settingsbg;
            color: $settingstext;
        }
    }
}
.act-filter{
    margin-top: -15px;
}
.activity-list{
    .table-nowrap{
        th{
            &:nth-child(2){
                width: 43%;
            }
        }
    }
}
