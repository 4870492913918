//
// _table.scss
//
.table > :not(caption) > * > * {
  padding: 8px 10px !important;
  vertical-align: middle;
}
.custom-header-css {
  &.table {
    background: $white !important;
  }
}
.table {
  tr {
    border: 0;
  }

  th {
    font-weight: $font-weight-bold;
    border: 0px;
    border-bottom: 1px solid $border-color-line;
    background: $white;

    div {
      span {
        position: relative;
        font-size: 14px;
        font-weight: 600;
        color: $dark-grey;

        &::after {
          border: 5px solid transparent;
          content: "";
          display: block;
          height: 0;
          right: -16px;
          top: 0;
          position: absolute;
          width: 0;
        }
      }

      span.desc {
        &::after {
          border-bottom-color: #666;
          top: 2px;
        }
      }

      span.asec {
        &::after {
          top: 8px;
          border-top-color: #666;
        }
      }
    }
  }

  td {
    border: 0 !important;
    font-size: 14px;
    font-weight: 500;
    color: $dark-grey;
    background: $white;
    // padding-left: 0 !important;

    &:first-child {
      padding-left: 18px !important;
    }

    &.table-details {
      padding: 0 !important;
    }

    .action-filed {
      width: 100%;
      text-align: center;
      min-width: 126px;
    }
    span {
      text-align: center;

      &.status {
        display: block;
        width: 100%;
        border-radius: 50px;
        padding: 4px 8px;
        color: white;
      }
      &.none {
        color: unset;
        // background: $border-color-line;
      }
      &.draft {
        background-color: $primary-color;
      }

      &.submitted {
        background-color: $sucess-color;
      }
      &.completed {
        background-color: $badge-color-color;
      }
      &.re-submit {
        background: $dark-primary-icon;
      }
      &.request {
        background: $cyan;
      }
      &.review {
        background: $primary-light;
      }
    }
  }

  .sub-table {
    padding-left: 60px;
    background: $sub-table;
    position: relative;

    h5 {
      font-size: 16px;
      font-weight: 500;
      color: $dark-grey;
      padding: 20px 0px;
      border-bottom: 1px solid $border-color;
      text-align: center;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      color: $dark-grey;
      text-align: center;
    }
  }
}

.table-dark > :not(:last-child) > :last-child > * {
  border-bottom-color: $table-dark-border-color;
}

// .table-bordered {
//   border: $table-border-width solid $table-border-color;
// }

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

.table > :not(:first-child) {
  border-top: 0;
}
.accordion-style {
  .card {
    .card-body {
      padding: 0;
      .table-filter-section {
        padding: 25px;
        padding-bottom: 0;
        display: flex;
        align-items: center;
      }
    }
  }
  th {
    &:first-child {
      border: 0;
    }
    &:last-child {
      width: 30px;
      border-bottom: 0;
    }
  }
}

.table-content {
  .card-body {
    padding: 0;
  }
}
.file-status {
  p {
    padding: 3px 12px;
    display: inline-block;
    max-width: 143px;
    min-width: 95px;
    text-align: center;
    margin: 0;
    border-radius: 8px;
    text-transform: capitalize;
    font-weight: 500;
    line-height: 20px;
    font-size: 13px;
  }
  &.approved {
    p {
      color: $status-app-text;
      background: $status-approved;
    }
  }
  &.review {
    p {
      color: $status-review-text;
      background: $status-review;
    }
  }
  &.rejected {
    p {
      color: $status-reg-text;
      background: $status-rejected;
    }
  }
  &.in_progress {
    p {
      color: $userstext;
      background: $usersbg;
    }
  }
  &.failed {
    p {
      color: $documenttext;
      background: $documentbg;
    }
  }
}
.file-action-btns {
  display: none;
  ul {
    @include flex-center;
    margin: 0;
    li {
      margin: 0 4px;
      cursor: pointer;
    }
  }
}
.table-hover {
  tbody {
    tr {
      &:hover {
        .file-action-btns {
          display: block;
        }
        .more-action {
          display: none;
        }
      }
    }
  }
}
.custom-header-css {
  &.my-doc {
    tbody {
      tr {
        td {
          &:last-child {
            width: 80px;
            padding-right: 0;
            text-align: center;
          }
        }
      }
    }
  }
}
