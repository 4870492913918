
.date-range-picker {
    .rs-stack {
        padding: 0 8px;
        border: 0;
    }

    .rs-picker-toggle-value {
        color: transparent;
    }

    .rs-picker-default {
        .rs-stack{
            display: block;
        }
        .rs-picker-toggle.rs-btn {
            padding: 0;
            border: 0 !important;
            box-shadow: none;
            display: inline;
            z-index: 0;
        }
    }
    .rs-picker-toggle-value,
    .rs-picker-toggle-placeholder{
        display: none;
    }
    #date-range-picker{
        display: none;
    }
}
.rs-picker-daterange {
    .rs-picker-toggle.rs-btn{
        .rs-picker-toggle-caret {
                position: relative;
                top: -3px;
                right: 0;
                width: 18px;
                height: 18px;
                fill: $primary-color;
            }
    }
} 
.rs-stack{
    padding: 20px;
    @include borderRadius_15;
    border: 0;
}
.rs-picker-daterange-predefined.rs-stack{
    padding: 0;
    border: 0;
    height: 100%;
    .rs-btn-sm {
        font-size: 14px;
        line-height: 20px;
        padding: 5px 20px 5px 0;
        text-decoration: none;
        color: $dark-grey;
    }
}
.rs-calendar-table-cell-selected {
    .rs-calendar-table-cell-content{
        background-color: $primary-color;
        @include borderRadius_30;
    }
}
.rs-picker-daterange-calendar-group {
    background: #F4F4F4;
    padding: 15px;
    @include borderRadius_15;
}
.rs-picker-daterange-header{
    display: none;
}
.rs-calendar-table-cell-in-range {
    .rs-calendar-table-cell-content{
        background-color: $daterangebg;
        border-radius: 0; 
        color: $primary-color;
    }
}
.rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content{
    background-color: $daterangebg;
    color: $primary-color;
}
.rs-calendar-table-cell, .rs-calendar-table-header-cell{
    padding: 0;
    width: 0;
}
.rs-picker-menu .rs-picker-toolbar-ranges{
    padding: 0;
}
.rs-calendar-table-cell-is-today {
    .rs-calendar-table-cell-content{
        background: $primary-color;
        color: $white;
        @include borderRadius_30;
        border: 0;
        box-shadow: none;
    }
}
.rs-calendar-table-cell-selected{
    &.rs-calendar-table-cell-selected-end{
        background: $daterangebg;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
    }
    &.rs-calendar-table-cell-selected-start{
        background: $daterangebg;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px; 
    }
}
.rs-picker-toolbar.rs-stack {
    padding-bottom: 0;
    justify-content: start !important;
    flex-direction: row-reverse;
    padding-left: 0;
}
button.rs-btn.rs-btn-primary {
    padding: 7px 15px;
    min-width: 100px;
    @include borderRadius_30;
    margin-right: 10px;
    background: $primary-color;
    &:hover{
        background: transparent;
        border: 1px solid $primary-color;
        color: $primary-color;
    }
}
button.rs-btn.rs-btn-default {
    min-width: 100px;
    padding: 7px 15px;
    border-radius: 30px;
    background: transparent;
    border: 1px solid $primary-color;
    color: $primary-color;
    &:hover{
        background: $primary-color;
        border: 1px solid $primary-color;
        color: $white;
    }
}
.rs-picker-daterange-calendar-group{
    height: auto;
}
.rs-picker-daterange-menu .rs-calendar{
    height: auto;
    padding-bottom: 0;
}