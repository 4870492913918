// Models Styles
.model-header{
    @include justify-between;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.page-content {
    .card-body {
        &.b-radius-x-0 {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }
}

.models-layout{
    .card-body{
        padding: 30px;
    }
    .tab-content{
        padding: 20px;
    }
}

.progress-tab{
    position: relative;
    .tabs-group{
        @include justify-between;
        padding-right: 35px;
        .tab-item{
            @include flex-center;

            .pro-label{
                padding-left: 20px;
                font-size: $large;
                color: $gainsboro;
                font-weight: $medium;
            }
            .tabcount {
              @include box(50px, 50px);
               background: $gainsboro;
               @include borderRadius_30;
              @include flex-center;
              font-size: $large;
              color: $white;
              img{
                display: none;
            }
           }
           &.active-tab{
            .tabcount {
                background: $primary-color;
               
            }
            .pro-label{
                color: $dark-grey;
            }
           }
           &.success{
            cursor: pointer;
            .tabcount {
                background: $primary-color;
                span{
                    display: none;
                }
                img{
                    display: block;
                }
            }
            .pro-label{
                color: $dark-grey;
            }
           }
        }
    }
    .progress{
        height: 5px;
        background: $gainsboro;
        margin: 30px 0;
    }
}

.input-left{
    background: $snow-drift;
    padding: 22px;
    @include borderRadius_15;
    .secountry-btn{
        padding: 5px 20px;
    }
    .btn-common{
        padding: 4px 15px;
        font-size: 13px;
        height: 35px;
    }
}
.dropcontext {
    background: $white;
    @include borderRadius_15;
    .drop-header{
        padding: 25px;
        border-bottom: 1px solid $input-border-color;
        @include justify-between;
    }
    .drop-body{
        padding: 25px;
    }
    .input-item{
        padding-bottom: 10px;
        margin-bottom: 10px;
        .input-settings{
          @include flex-center-vert;
          grid-gap: 18px;
          .form-control{
            margin: 0;
          }
      }
    }
    .handle-drop{
        @include flex-center-vert;
        .bx{
            font-size: $xxxlarge;
            color: $grey-color;
            &.dots-right{
                margin-left: -17px;
            }
        }
    }
    .close-icon{
        .bx{
            font-size: $xxxlarge;
            color: $gainsboro;
            position: relative;
            top: 2px;
            opacity: 1;
        }
        .fas {
            font-size: $small;
            color: $gainsboro;
            position: relative;
            top: 6px; 
        }
        &.wind-up{
            .bx{
                font-size: 27px;
                color: $red;
            }
        }
        .setting{
            color: $blue;
        }
      }
}
.new-section {
    display: flex;
    justify-content: flex-end;
}


// Right Col
.input-right-col{
    padding: 20px;
    background: $snow-drift;
    height: 100%;
    @include borderRadius_15;
    .form-control{
        height: 48px;
    }
    .tabsub-heading{
        font-size: $small;
        font-weight: $medium;
    }
    textarea.form-control {
        min-height: 150px;
    }
    .label-dec{
        font-size: 13px;
        color: $labeldec;
        display: block;
        margin-bottom: 5px;
    }
}
.minmax-group{
    display: flex;
    grid-gap: 20px;
}

//Placeholder image style
.placeholder-image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 90px 0;
    .primary-button{
        margin-top: 40px;
    }
    p{
        margin-top: 20px;
    }
    .tracking-btns{
        margin-top: 10px;
       @include flex-center-vert;
        .primary-button{
            margin-top: 0;
            margin-right: 15px;
        }
        .secountry-btn{
            padding: 8px 20px;
        }
        .btn-common{
            min-width: 160px;
        }
    }
}
.filesprogress {
    @include flex-center;
    .animated-progess {
        margin-top: 30px;
        width: 85%;
        .progress {
            height: 22px;
            @include borderRadius_30;
            border: 1px solid $primary-color;
            background: transparent;
        }
        .progress-bar {
            @include borderRadius_30;
            background: $primary-color;
            margin: 3px 4px;
        }
    }
    p {
        margin-top: 10px;
    }
}

//Traning style
.table-heading{
    font-size: $xlarge;
    font-weight: $medium;
    line-height: $xlarge;
    color: $tablehead;
}

//MOdel Details
.model-details{
    // background: $white;
    @include borderRadius_15;
    .nav-tabs-custom{
        &.nav-detail{
            padding: 25px 25px 0 30px;
            background: $white;
            .nav-item{
                .tab-item{
                    padding: 0 20px 15px 20px;
                }
            }
        }
    }
}
.last-update{
    display: inline-block;
    background: #ECECEC;
    margin: 0;
    padding: 10px 25px;
    border-radius: 30px;
    p{
        margin: 0;
    }
    span{
        padding-left: 20px;
    }
}
.table-filters{
    @include justify-between;
    padding-bottom: 15px;
    border-bottom: 1px solid $border-color-line;
}
//approve-stages 
.approve-stages{
    position: relative;
  ul{
    li{
        background: $colorsnow;
        flex-grow: 1;
        @include borderRadius_15;
        .accordion-item{
            border: 0;
        }
        .accordion-button{
            box-shadow:  none;
            background: transparent;
            color: $dark-grey;
            font-size: $fs-medium;
            font-weight: $semibold;
        }
    }
  }
  .reviewer-check{
    @include flex-center-vert;
  }
  .stage-remove {
    @include box(42px, 42px);
    background: $colorsnow;
    @include flex-center;
    @include borderRadius_30;
    cursor: pointer;
}
.newappend{
    @include box(42px, 42px);
    background: $primary-color;
    @include flex-center;
    @include borderRadius_30;
    cursor: pointer;
    .bx{
        color: $white;
        font-size: $large;
    }
}
}
.stages-item {
    display: flex;
    width: 100%;
    grid-gap: 15px;
    margin-bottom: 15px;
}
.append-group{
    display: flex;
    grid-gap: 15px;
}
.approve-content{
    padding: 10px;
    .reviewer-check{
        .form-check{
            &:first-child{
                margin-right: 30px;
            }
            
    }
}
.bot-line{
    padding-bottom: 20px;
    border-bottom: 1px solid $input-border-color;
}
.preheader-text{
    color: $nobel-color;
}
// child .accordion
.flag-rule-child{
    display: flex;
    padding-top: 15px;
    .title-left{
        width: 75px;
    }
    .child-accordion{
        flex-grow: 1;
        .accordion{
            background: $white;
            @include borderRadius_10;
        }
        .accordion-button{
            padding: 15px 20px;
            font-size: $lite;
            font-weight: $medium;
        }
    }
    .accordion-item{
        margin-bottom: 15px;
        .rule-remove{
            position: absolute;
            right: 20px;
            margin-right: 30px;
        }
    }
    .accordion-body{
        border-top: 1px solid $input-border-color;
        .rule-content{

        }
    }
    .preheader-text{
        color: $nobel-color;
    }
    .validation-rule{
        padding-top: 30px;
        padding-bottom: 20px;
        p{
            margin: 0;
            font-size: 13px;
            font-weight: $medium;
        }
    }
}
}
.advanced-option {
    @include flex-center-vert;
    padding-top: 25px;
    .option-cont{
        padding-left: 15px;
    }
}
.more-rule{
    font-size: 13px;
    font-weight: $medium;
    color: $primary-color;
    text-decoration: underline;
    margin-left: -75px;
    cursor: pointer;
}
.table-btns {
    .btn-common {
        &:hover {
            img {
                filter: inherit;
            }
        }
    }

    .btn-common {
        img {
            filter: brightness(10);
        }
    }
}
.status-review{
    padding: 3px 12px;
    display: inline-block;
    max-width: 143px;
    min-width: 95px;
    text-align: center;
    margin: 0;
    border-radius: 8px;
    text-transform: capitalize;
    font-weight: 500;
    line-height: 20px;
    font-size: 13px;

    &.trained{
        background: $status-approved;
        color: $status-app-text;
    }
    &.pending{
        background: $model-pending-bg;
        color: $model-pending-text;
    }
    &.labelled {
        color: $documenttext;
        background: $documentbg;
    }
    &.draft {
        color: $status-reg-text;
        background: $status-rejected;
    }
}

.input-fields-create-model {
    .active {
        i {
            color: $primary-color !important;
        }
    }
}
.model-details {
    .nav-tabs-custom {
        border-radius: 15px 15px 0 0;
    }
    .models-layout{
        .card-body{
            border-top-left-radius: 0;
            border-top-right-radius: 0;   
        }
    }
}
.publish {
    .publish-content {
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            &.content {
                font-size: 14px;
                font-weight: 500;
                padding: 15px;
                margin: 0;
                display: flex;

                .bx-info-circle {
                    font-size: 23px;
                }
            }
        }
    }
}

button.document-status {
    margin: 0 auto;
    height: 35px;
}
.model-status {
    font-size: 14px;
    display: inline-block;
    min-width: 100px;
    text-align: center;
    padding: 3px 10px;
    border-radius: 8px;

    &.published {
        color: $status-app-text;
        background: $status-approved;
    }

    &.draft {
        color: $status-reg-text;
        background: $status-rejected;
    }
    &.not_started {
        color: $status-reg-text;
        background: $status-rejected;
    }
    &.labelling{
        color: $settingstext;
        background: $settingsbg;
    }
    &.trained{
        color: $userstext;
        background: $usersbg;
    }
    &.pending{
        color: $settingstext;
        background: $settingsbg;
    }
    &.in_progress{
        color: $documenttext;
        background: $documentbg;
    }
}

// starting button blinking dot
.dot {
    background-color: orange;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    animation: blink 600ms infinite;
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    25% {
        opacity: 0.5;
    }

    50% {
        opacity: 0;
    }

    75% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}