.reviewpage textarea {
    height: 135px !important;
    overflow: hidden;
}

.invoice-title {
    @include justify-between;
    padding: 15px 20px;
    border-bottom: 1px solid $border-color-line;
}

.main-parent {
    display: flex;
    overflow: hidden;
    grid-gap: 15px;
    position: relative;
    height: calc(100vh - 155px);
    // display: grid;
    // grid-gap: 20px;
    // grid-template-columns: 115px 60% 30%;

    .other-pages {
        flex: 0 0 120px;
        overflow: auto;
        height: 100%;
        padding: 10px;
        background: $colorsnow;
        position: relative;

        .back-btn {
            text-align: center;
            padding: 20px 0;
        }

        .page-group {
            ul {
                text-align: center;

                li {
                    list-style: none;
                    padding: 12px 0;

                    img {
                        cursor: pointer;
                    }
                }
            }
        }

        .move-next {
            position: fixed;
            bottom: 30px;
            margin-left: 0px;

            ul {
                justify-content: center;

                li {
                    width: 25px;
                    height: 25px;
                    background: $white;
                    border-radius: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    margin: 0 5px;
                    list-style: none;
                    border: 1px solid $icon-grey;
                    &:first-child{
                        margin-left: 0;
                    }

                    .bx {
                        font-size: 20px;
                        color: $icon-grey;

                    }
                }
            }
        }
    }
    .preview{
        flex: 1 1 0%;
        margin: 0 25px;
        .card{
            margin: 0 auto;
        }
        .review-doc {
            height: calc(100vh - 150px);
            // height: calc(100vh - 190px);
            overflow: scroll;
        }
        // &::after{
        //     content: '';
        //     position: fixed;
        //     top: auto;
        //     left: 0;
        //     bottom: 0;
        //     background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 80%);
        //     width: 100%;
        //     height: 100px;
        // }
    }
}

.review-part {
    img {
        display: block;
        // width: 100%;
        margin: 0 auto;
    }
}

.review-layout {
    position: relative;
    height: 100vh;
    .page-content {
        padding: calc(50px + 20px) calc(24px * 0.75) 60px calc(0px * 0.75);
        height: 100%;
    }

    .zoom-controls {
        background: $darkgrak;
        padding: 12px 12px;
        @include flex-center-vert;
        @include borderRadius_30;
        min-width: 210px;

        button {
            background: transparent;
            border: 0;

            .bx {
                color: $white;
                display: block;
                font-size: 24px;
                transition: all .4s;
                margin: 0 10px;
            }

            &:hover {
                .bx {
                    -webkit-transform: scale(1.2);
                    transform: scale(1.2);
                }
            }
        }

        span {
            font-size: 16px;
            font-weight: 500;
            color: $white;
            min-width: 50px;
            text-align: center;
        }
    }
}
.reviewpage{
    .custom-input{
        .form-control{
            height: 43px;
        }
    }

    .field-selected {
        border: 2px solid $blue;
    }
}

.review-table {
    .label-header {
        input {
            &.field-selected {
                border: 2px solid $blue;
            }
        }
    }
}

.control-group {
    @include justify-between;

    .redo {
        img {
            transform: rotate(270deg);
        }

    }
}

.review-table {
    position: relative;
    overflow-x: auto;

    table {
        position: relative;
        width: 25vw;

        tr {
            th {
                width: 100%;
                padding: 10px;
            }

            td {
                width: 100%;
                padding: 10px;
            }
        }
    }

    .table_select {
        width: 150px;
        position: relative;
    }

    .label-header {
        position: relative;
        width: 150px;

        input {
            width: 100%;
            border: 1px solid $input-border;
            height: 40px;
            border-radius: 7px;
            padding-right: 35px;
        }

        .check-img {
            position: absolute;
            right: 10px;
        }
    }

    .drop-down {
        [class*="-control"] {
            border: 1px solid $input-border !important;
            border-radius: 7px;

            [class*="-singleValue"],
            [class*="-placeholder"] {
                font-weight: 400;
                color: #BABABA;
            }
        }

        [class*="-menu"] {

            // box-shadow: none;
            [class*="-option"] {
                padding: 8px 20px;
                font-weight: 400;
            }
        }

        .custom_select__value-container {
            padding: 5px 8px;

        }
    }
}

.invoice-info {
    // position: fixed;
    // top: 0;
    // right: 0;
   // margin-top: 70px;
    width: 400px;
    height: 100%;
    .card {
        height: 100%;
    }
}
.review-section-group {
    flex: 1 1 0%;
    .document-review{
        display: flex;
    }
}
.table-accordion {
    .accordion-button {
        font-size: 14px;
        font-weight: 600;
        background: transparent;
        color: $dark-grey
    }

    .accordion-item {
        border: 0;
    }
}
.section-footer{
    position: fixed;
    right: 40px;    
    bottom: 30px;
    .table-grop-btn {
        @include flex-center-vert;
        justify-content: center;
      
         .primary-button {
            margin: 0 15px;
        }
    }
}

.call-to {
    position: relative;
    z-index: 1;
    padding: 20px;
    margin-top: -100px;
    // background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    // position: fixed;
    // width: 75%;
    // bottom: 50px;
    // margin-left: 150px;
}

//Download
.download-file {
    position: absolute;
    right: 0;
    bottom: 30px;
    width: 170px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 0 0px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
    -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

    ul {
        padding: 0;
        margin: 0;

        li {
            padding: 10px 15px;
            list-style: none;
            color: #333333;
            cursor: pointer;
            @include justify-between;
            border-bottom: 1px solid $input-border;

            &:last-child {
                border-bottom: 0;
            }
        }
    }
}

.page-title {
    font-weight: 600;
    font-size: $xxxlarge;
    line-height: 20px;
    color: #393939;
    margin: 0;
    padding-bottom: 20px;

}

// Doc Filters
.filters-group {
    padding: 0;
    margin: 0;
    @include flex-center-vert;

    .clear-filter {
        padding-left: 10px;
        color: $primary-color;
        font-size: 16px;
        cursor: pointer;
        text-decoration: underline;
        margin-bottom: 0;
    }
}

.pop-up-invoice-field-selection {
    width: 320px;
    padding: 20px;
    border-radius: 15px;
    background: white;
    position: absolute;
    left: 0;
    display: none;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 0px 25px;
    cursor: default;
    height: max-content;

    &.custom-input {
        textarea {
            min-height: 80px;
        }

        p {
            float: left;
            margin-bottom: 5px;
        }

        .action {
            display: flex;

            .secountry-btn {
                margin-right: 10px;
                border: none;
                background: $review-approved;
                color: $review-approved-text;
                font-size: 14px;

                &:hover {
                    border: none !important;
                    background: $review-approved !important;
                    color: $review-approved-text !important;
                    img {
                        filter: inherit;
                    }
                }
                img{
                    width: 15%;
                    margin: inherit;
                }
            }

            .primary-button {
                border: none;
                background: $review-delete;
                color:  $review-delete-text;
                font-size: 14px;

                &:hover {
                    border: none !important;
                    background: $review-delete !important;
                    color: $review-delete-text !important;
                    img {
                        filter: inherit;
                    }
                }
                img{
                    width: 15%;
                    margin-right: 8px;
                    margin-left: 0;
                }
            }
        }

        .close-click {
            position: absolute;
            right: 10px;
            top: 7px;
            cursor: pointer;
            .mdi{
                font-size: 18px;
                opacity: 0.8;
            }
        }
    }
}

.page-title {
    font-weight: 600;
    font-size: $xxxlarge;
    line-height: 20px;
    color: #393939;
    margin: 0;
    padding-bottom: 20px;

}

// Doc Filters
.filters-group {
    padding: 0;
    margin: 0;
    @include flex-center-vert;

}

.checkboxes {
    padding: 15px 20px;
    max-height: 120px;
    overflow: auto;
    .checkbox-item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        &:last-child{
            margin-bottom: 0;
        }
        
        input {
            margin-top: 0
        }

        label {
            margin-bottom: 0;
            text-transform: capitalize;
            cursor: pointer;
        }
    }
}

.table-footer{
    @include justify-between;
    .select-download{
        @include flex-center-vert;
        span{
            color: $primary-color;
            font-weight: $bold;
            font-size: $lite;
        }
    }
}
.file-preview{
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    width: 40px;
    height: 40px;
    .image-overlay{
        width: 100%;
        height: 100%;
        background: #00000057;
        position: absolute;
        @include flex-center;
        .mdi{
            font-size: 20px;
            color: $white;
            opacity: 0.9;
        }
    }
}
.check-row{
    width: 30px;
    text-align: center;
}

.checkbox-head {
    text-align: center;
    width: 30px;
    padding-left: 18px;
}

.copy-link-model {
    .head-item {
        display: flex;
        justify-content: flex-end;
    }

    .action {
        display: flex;
        justify-content: space-between;

        .link-field {
            margin: 0;
            padding: 10px;
            border-radius: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 330px;
            background: $review-approved;
            color: $review-approved-text;
        }

        .copy-link {
            margin: 0;
            padding: 10px 0;
            color: $blue;
            cursor: pointer;
        }
    }
}
.reviewpage{
    max-height: 300px;
    // min-height: 300px;
    overflow: auto;
    overflow-x: hidden;
}

.sidebar-container {
    overflow: auto;
    max-height: 500px;
    overflow-x: hidden;
    .accordion-button::after {
        display: none;
    }
}

.table-section {
    .accordion-button::after {
        display: none;
    }
}

.reviewpage {
    .field-label {
        border-bottom: 2px solid $blue;

        label {
            color: $blue;
            font-weight: 800;
        }
    }
}

.field-update-input-container {
    margin-top: 28px;
    min-height: 80px;
    text-align: left;
    overflow: auto;

    .field-input {
        background: transparent;
        border: 0;
        border-bottom: 1px solid black;
    }
}