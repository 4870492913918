// 
// _pagination.scss
// 

// Pagination rounded (Custom)
.pagination-rounded {
    .page-link {
        border-radius: 30px !important;
        margin: 0 3px !important;
        border: none;
        width: 32px;
        height: 32px;
        padding: 0;
        text-align: center;
        line-height: 32px;
    }
}

.table-pagiation {
    @include flex-center-horiz;
    button {
        @include box(38px, 38px);
        @include flex-center;
        @include borderRadius_30;
        background: $pagination-bgcolor;
        grid-gap: 20px;
        &.last-page{
            background: $primary-color;
        }
        .bx {
            color: $white;
            font-size: $xxxlarge;
        }
    }
    .prev-group {
        &.last-page {
            button {
                background: $primary-color;
            }
        }
    }
    .next-group{
        &.next-page {
            button {
                background: $primary-color;
            }
        }
    }
    .prev-group,
    .next-group {
        display: flex;
        grid-gap: 12px;
    }

    .current-group {
        @include flex-center;
        grid-gap: 12px;
        padding: 0 15px;

        p {
            font-size: $lite;
            font-weight: $medium;
            color: $dark-grey;
            margin: 0;
        }

        .current-page {

            .form-control {
                width: 60px;
                height: 43px;
                border-radius: 5px;
                text-align: center;
                font-size: $lite;
                font-weight: $medium;
                color: $dark-grey;
                background: $content-bg;
            }
        }
    }
}

[data-layout-mode="dark"] {
    .table-pagiation .current-group {
        .form-control {
            background: $dark-dropdown-bg !important;
            color: $white;
        }

        p {
            color: $white;
        }
    }
}