// 
// _nav.scss
// 

.nav-tabs,.nav-pills {
  > li {
      > a {
          color: var(--#{$prefix}gray-700);
          font-weight: $fw-medium;
      }
  }
}

.nav-pills {
  > a {
      color: var(--#{$prefix}gray-700);  
      font-weight: $fw-medium;
  }
  .active {
    &.nav-link{
      background:  $gray-600;
      border-radius: 25px;
    }
  }
}


.nav-tabs-custom {
  border-bottom: 2px solid var(--#{$prefix}gray-300);
  @include justify-between;
  .nav-item {
    position: relative;
    color: var(--#{$prefix}dark);
    flex-grow: unset;
    flex-basis: unset;
    margin-right: 50px;
    .tab-item {
     @include flex-center-vert;
     cursor: pointer;
     padding-bottom: 15px;
     &.active{
      border-bottom: 2px solid $primary-color;
      span{
        color: $primary-color;
      }
     }
  }
  .tab-icon{
    margin-right: 8px;
  }
    span{
      font-size: 14px;
      font-weight: 500;
    }
    .nav-link {
      border: none;

      &::after {
        content: "";
        background: $primary;
        height: 2px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -1px;
        transition: all 250ms ease 0s;
        transform: scale(0);
      }

      &.active{
        color: $primary-color;
        &:after{
          transform: scale(1);
        }
      }

    }
  }
}


// vertical nav

.vertical-nav{
  .nav{
      .nav-link{
          padding: 24px 16px;
          text-align: center;
          margin-bottom: 8px;
          .nav-icon{
              font-size: 24px;
          }
      }
  }
}