//Download modal
.doc-modal {
    .modal-body {
        padding: 15px;
    }
    .doc-download {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr 1fr;
        grid-gap: 25px;
        padding-bottom: 30px;
        .doc-download-item {
            @include borderRadius_15;
            border: 1px solid $primary-color;
            padding: 20px 25px;
            width: 100%;
            cursor: pointer;
            @include justify-between;
            h6 {
                font-size: $large;
                font-weight: $semibold;
                color: $dark-grey;
                margin: 0;
                img{
                    margin-right: 10px;
                }
                span {
                    font-size: $lite;
                    font-weight: $medium;
                }
            }
            &.active{
                background-color: #FFF2EF;
                .head-before {
                    span {
                       
                        border: 5px solid $primary-color;
                        border-radius: 50px;
                    }
                }
            }
        }
        .download-title{
            @include flex-center-vert;
            width: 100%;
            grid-gap: 10px;
            .file-option{
                @include justify-between;
                width: 100%;
            }
        }
        .head-before {
            span {
                width: 20px;
                display: block;
                height: 20px;
                border: 1px solid red;
                border-radius: 50px;
            }
        }
    }
    .assign-user {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 25px;
        .users-list {
            margin: 0;
        }
    }
}

.advance-option{
    padding-bottom: 15px;
    margin-bottom: 15px;
    .accordion-item{
        border: 0;
        .accordion-button{
            background: $snow-drift;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            @include FontMedium;
            color: $grey-color;
            box-shadow: none;
            &.collapsed{
                @include borderRadius_15;
            }
        }
        .accordion-body{
            background: $snow-drift;
        }
    }
    .opt-filter{
        @include justify-between;
        padding-bottom: 20px;
        border-bottom: 1px solid $input-border;
        ul{
            margin: 0;
            padding: 0;
            li{
                font-size: $lite;
                font-weight: $medium;
                color: $dark-grey;
                margin-right: 20px;
                cursor: pointer;
                &.disabled{
                    opacity: 0.5;
                }
            }
        }
    }
   .select-option {
    .primary-button{
        min-width: 70px;
        padding: 5px 15px;
        height: 28px;
    }
 
   }
   .selected-filed {
    padding-top: 20px;
       ul {
           li {
               padding: 5px 0;
               @include justify-between;
               cursor: pointer;
               .form-check{
                label{
                    padding-left: 15px;
                    margin: 0;
                    cursor: pointer;
                }
               }
               .move-icon{
                cursor: pointer;
                    .bx{
                        font-size: $xxxlarge;
                        color: $border-bg;
                        &.dots-right{
                            margin-left: -30px;
                        }
                    }
                    
               }
           }
       }
   }
}
.download-btn{
    .btn-primary{
        margin-right: 20px;
    }
    .secountry-btn{
        margin-right: 20px;
    }
}

//File Upload
.create-folder {
    .form-group {
        margin: 15px 0;
        .form-control {
            background: $content-bg;
        }
    }
    .modal-content {
        padding: 20px 25px;
        @include borderRadius_15;
    }
    .secountry-btn {
        margin-right: 15px;
    }
    .head-item{
        @include justify-between;
        width: 100%;
    }
}

.image-model {
    .modal-content {
        background: transparent;
        border: transparent;
    }

    .head-item {
        justify-content: end;

        span {
            background: $primary-color;
            padding: 5px;
            @include borderRadius_30;
            position: absolute;
            right: 10px;
            top: 6px;
            display: flex;

            .bx {
                color: $white;
                opacity: 1;
            }
        }
    }
}

.close-icon{
    .bx{
        font-size: $xxxlarge;
        color: $dark-grey;
        opacity: 0.5;
        cursor: pointer;
        
    }
}

.upload-files-modal {
    &.modal-document-upload {
        .uploading-files {
            .file-uploader {
                height: 250px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }
        }
    }

    .upload-container {
        .card{
            height: 100%;
        }
    }

    .dropzone-previews {
        overflow: auto;
        max-height: 180px;
    }

    .filter-btn {
        justify-content: flex-end;
        margin-top: 20px;
    }
}

@media (min-width: 576px) {
    .create-folder {
        &.modal-sm {
            --bs-modal-width: 460px;
        }
        .form-group{
            margin: 15px 0;
            .form-control{
                background: $content-bg;
            }
        }
    }

}


//Files Upload style
.files-upload{
    position: fixed;
    right: 0;
    bottom: 0;
    background: $content-bg;
    width: 350px;
    @include boxshadow;
    .upload-header{
        padding: 15px 25px;
        @include justify-between;
        width: 100%;
        background: $primary-color;
        h5{
            color: $white;
            font-size: $small;
            margin: 0;
        }
        .up-down-arrow{
            .bx{
                color: $white;
                font-size: 25px;
                cursor: pointer;
            }
        }
        .close-icon{
            .bx{
                color: $white;
                font-size: 25px;
                opacity: 1;
                cursor: pointer;
            } 
        }
    }
    .item-group{
        position: relative;
        transition: all 0.3s ease-in-out;
        p{
            font-size: $lite;
            font-weight: $regular;
            color: $darkcharcoal; 
            margin: 0;
            padding: 10px 25px;
        }
        ul{
            background: $white;
            padding: 0;
            margin: 0;
            max-height: 350px;
            overflow-y: scroll;
            li{
                padding: 15px 30px;
                @include justify-between;
                width: 100%;
                border-bottom: 1px solid $input-border-color;
                &:last-child{
                    border-bottom: 0;
                }
                &:hover{
                    background: $uploadhover;
                }
                .file-items{
                    flex-grow: 1;
                    padding: 0 10px;
                    h6{
                        font-size: $fs-medium;
                        font-weight: $regular;
                        color: $darkcharcoal;
                        margin: 0;
                    }
                    span{
                        font-size: $lite;
                        font-weight: $regular;
                        color: $darkcharcoal; 
                    }
                }
                .file-status{
                    .status-type{
                        @include box(30px, 30px);
                        background: $primary-color;
                        @include borderRadius_30;
                        @include flex-center;
                        .bx{
                            color: $white;
                            font-size: $xxxlarge;
                        }
                    }
                }
            }
        }
    }
}
