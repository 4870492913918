
//Border-Radius
@mixin borderRadius_10 {  
    -webkit-border-radius: 10px;  
       -moz-border-radius: 10px;  
        -ms-border-radius: 10px;  
            border-radius: 10px;  
} 
@mixin borderRadius_15 {  
    -webkit-border-radius: 15px;  
       -moz-border-radius: 15px;  
        -ms-border-radius: 15px;  
            border-radius: 15px;  
} 
@mixin borderRadius_30 {  
    -webkit-border-radius: 30px;  
       -moz-border-radius: 30px;  
        -ms-border-radius: 30px;  
            border-radius: 30px;  
} 
@mixin borderRadius_50 {  
    -webkit-border-radius: 50px;  
       -moz-border-radius: 50px;  
        -ms-border-radius: 50px;  
            border-radius: 50px;  
} 

//Flex 
@mixin flex-column {
    display: flex;
    flex-direction: column;
}

@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-center-column {
    @include flex-center;
    flex-direction: column;
}

@mixin flex-center-vert {
    display: flex;
    align-items: center;
}

@mixin flex-center-horiz {
    display: flex;
    justify-content: center;
}

// Box-width
@mixin box($width, $height: $width) {
    width: $width;
    height: $height;
}

@mixin justify-between{
    @include flex-center-vert;
    justify-content: space-between;
}

//Filters box-shadow 
@mixin boxshadow{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
//Font Styles

@mixin FontMedium{
    font-weight: $medium;
    color: $dark-grey;
    font-size: $fs-medium;

}
