.uploading-progress {
    position: fixed;
    right: 0;
    bottom: 0;
    background: $white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    width: 350px;
    margin-right: 24px;

    &.hide {
        .header {
            .icons {
                .close-icon {
                    transform: rotate(180deg);
                }
            }
        }

        .body {
            .content {
                animation: decreaseHeight 0.6s ease-in-out forwards;
            }
        }

        .sub-header {
            padding: 10px 20px;
            background: $grey;
            animation: decreaseHeight 0.6s ease-in-out forwards;
        }
    }

    .header {
        padding: 14px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: $primary-color;
        color: $white;

        h5 {
            margin-bottom: 0;
        }

        .icons {
            display: flex;
            align-items: center;
            gap: 20px;

            span {
                transition: transform 0.6s ease-in-out;
                display: flex;
                align-items: center;
                padding: 10px 0;
                cursor: pointer;
            }
        }
    }

    .sub-header {
        padding: 10px 20px;
        background: $grey;
        animation: increaseHeight 0.6s ease-in-out forwards;
    }

    .body {

        .content {
            overflow: auto;
            animation: increaseHeight 0.6s ease-in-out forwards;

            .uploading-items {
                padding: 15px 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid $border-color-line;

                &:last-child {
                    border-bottom: 0;
                }

                .file-details {
                    display: flex;
                    align-self: center;
                    gap: 20px;
                }
            }

            .loader {
                width: 29px;
                height: 29px;
                border: 5px solid $grey;
                border-bottom-color: $primary-color;
                border-radius: 50%;
                display: inline-block;
                box-sizing: border-box;
                animation: rotation 1s linear infinite;
                margin: 0;
            }
        }
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes increaseHeight {
    0% {
        max-height: 0px;
    }

    100% {
        max-height: 270px;
    }
}

@keyframes decreaseHeight {
    0% {
        max-height: 270px;
    }

    100% {
        padding: 0;
        max-height: 0;
    }
}