//
// Forms.scss
//
// Styles for the Forms section
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  &::placeholder {
    text-align: left
      /*rtl: right*/
    ;
  }
}

.form-check {
  position: relative;
  text-align: left
    /*rtl: right*/
  ;
}


// checkbox input right

.form-check-right {
  padding-left: 0;
  display: inline-block;
  padding-right: $form-check-padding-start;

  .form-check-input {
    float: right;
    margin-left: 0;
    margin-right: $form-check-padding-start * -1;
  }

  .form-check-label {
    display: block;
  }
}



// checkbox

.form-checkbox-outline {

  .form-check-input {
    border-width: 2px;
    background-color: $card-bg;

    &:active {
      filter: none;
    }

    &:checked {
      background-color: $card-bg  !important;

      &[type=checkbox] {
        background-image: none;

      }

      &:after {
        position: absolute;
        content: '\F012C';
        font-family: "Material Design Icons";
        top: -4px !important;
        left: 1px;
        /*rtl: -4px */
        font-size: 16px;
        color: $dark;
      }
    }
  }
}

// radio

.form-radio-outline {
  .form-check-input {
    background-color: $card-bg;
    position: relative;

    &:active {
      filter: none;
    }

    &:checked {
      background-color: $card-bg  !important;

      &[type=checkbox] {
        background-image: none;

      }

      &:after {
        position: absolute;
        content: '';
        top: 3px !important;
        left: 3px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
      }
    }
  }
}



// checkbox color

@each $color,
$value in $theme-colors {
  .form-check-#{$color} {
    .form-check-input {
      &:checked {
        background-color: $value;
        border-color: $value;
      }
    }
  }

  .form-radio-#{$color} {
    .form-check-input {
      &:checked {
        border-color: $value;
        background-color: $value;

        &:after {
          background-color: $value;
        }
      }
    }
  }
}

.form-check,
.form-check-input,
.form-check-label {
  cursor: pointer;
  margin-bottom: 0;
}

// Switch sizes

.form-switch-md {
  padding-left: 2.5rem;
  min-height: 24px;
  line-height: 24px;

  .form-check-input {
    width: 40px;
    height: 20px;
    left: -0.5rem;
    position: relative;
  }

  .form-check-label {
    vertical-align: middle;
  }
}


.form-switch-lg {
  padding-left: 2.75rem;
  min-height: 28px;
  line-height: 28px;

  .form-check-input {
    width: 48px;
    height: 24px;
    left: -0.75rem;
    position: relative;
  }
}

.input-group-text {
  margin-bottom: 0px;
}

// transfer list

.rdl-filter,
.rdl-control {
  background-color: $input-bg;
  border-color: $input-border-color;
  color: $input-color;
}

.react-dual-listbox {
  button {
    color: $dark;
    border-color: $border-color;
    background-color: $light;
  }

  input,
  select {
    &:disabled {
      background-color: $input-disabled-bg;
    }
  }
}

.form-control {
    border-color: $white;
    border-radius: 10px;
    height: 53px;

    &.is-invalid {
        background-image: none;
        background: $error-bg;
    }

    &:focus {
        border-color: $userstext;
    }
  
}

// Error Message
.invalid-feedback {
    padding-left: 18px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 14px;
        height: 14px;
        background: url('../../../images/svg/alert-circle.svg');
        background-size: cover;
    }
}

// File Upload 
.field-upload{
    input{
        display: none;
    }
    
    .upload-field{
        padding: 10px;
        width: 100%;
        border: 2px dotted $primary-color;
        min-height: 53px;
        cursor: pointer;
        color: $primary-color;
        font-size: 14px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        // flex-direction: column;
    
        span{ 
            font-size: 20px;
            background: $light-primary;
            border-radius: 50px;
            font-weight: 600;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            
        }
        &.is-invalid{
            span{
                background: $white;
            }
          }
    }
    &.upload{
        .upload-field{
            display: none;
            &.view-file{ 
                display: flex;
                justify-content: space-between;
                div{
                    align-items: center;
                }
            }
        }
    }
}

// Input Color Option_two
.custom-input{
  .form-control{
    background: $colorsnow;
    height: 50px;
    margin-bottom: 15px;
  }
}
.label-header{
  @include justify-between;
  padding-bottom: 5px;
  label{
    margin: 0;
  }
}

//Check-Box Styles
.form-check-input{
  width: 15px;
  height: 15px;
  &:checked{
    background: $primary-color;
    border-color: $primary-color;
  }
}


// Toogle Switch
.rs-toggle .rs-toggle-presentation:after {
  @include borderRadius_30;
  height: 18px;
  left: 0px;
  top: -3px;
  width: 18px;
  background: $toggele_inactive;
}
.rs-toggle .rs-toggle-presentation {
 @include borderRadius_30;
  height: 12px;
  min-width: 35px;
  background: $toggele_inactive_bg;
}
.rs-toggle-checked .rs-toggle-presentation{
  background: $toggle_act_bg;
}
.rs-toggle-checked .rs-toggle-presentation:hover{
  background: $toggle_act_bg;
}
.rs-toggle.rs-toggle-checked .rs-toggle-presentation:after{
  background: $primary-color;
  top: -3px;
  left: 110%;
}

//Search Bar
.search-group {
  position: relative;
  input {
    border: 0;
    height: 30px;
    @include borderRadius_30;
    padding: 8px 25px 8px 15px;
    background: $colorsnow;
    min-width: 210px;
  }
  .search-icon{
    position: absolute;
    top: 7px;
    right: 10px;
    .bx{
      font-size: $large;
      color: #E7E7E7;
    }
  }
}