@media only screen and (max-width: 1560px) {
    .user-card-view {
        grid-template-columns: repeat(3, 1fr);

    } 
    
}
@media only screen and (max-width: 1350px) {
.tracking-status-row{
    .col-md-8{
        .col-md-3{
            width: 100%;
        }
        .col-md-9{
            width: 100%;
        }
    }
}
}
@media only screen and (max-width: 1290px) {
    div {
        &.value-col {
            width: 44%;
        }
    }

    .user-card-view {
        grid-template-columns: repeat(2, 1fr);

    }
}

@media only screen and (max-width: 1200px) {
    .cargo-types {
        width: 75%;
    }
}

@media only screen and (max-width: 998px) {

    .account-pages {
        padding: 0px 0;
        .auth-logo{
             img{
                width: 200px;
             }
           }
           .login-bg{
            display: none;
           }
           .auth-logo {
            display: block;
            padding-bottom: 25px;
        }
        .welcome-form{
            width: 70%;
            margin: 0 auto;
        }
    }
    #page-header-search-dropdown{
        display: none;
    }
    
    .settings {
        .addressInfo {
            display: none;
        }
    
        .addressInfo-res {
            display: block;
        }
    }
}
 

@media only screen and (max-width: 760px) {
    div {
        &.value-col {
            width: 100%;
            padding-top: 20px;
        }
    }

    .user-card-view {
        grid-template-columns: repeat(1, 1fr);

        .user-details {
            .user-info {
                flex-direction: column;

                .user-bio {
                    margin-top: 20px;
                    text-align: center;
                }
            }
        }
    }

   
}

@media only screen and (max-width: 600px) {
    .filter-section {
        flex-direction: column;
        align-items: end;
        margin-bottom: 0px !important;

        .action-field {
            width: 100%;
            margin-bottom: 10px;
        }

        .field {
            width: 50%;
        }
    }

  

   
}

@media only screen and (max-width: 530px) {
    
    .error-screen{ 
        .account-pages{
            .action-btn{
                flex-direction: column;
                div{
                    margin-bottom: 15px ;
                   a{
                        width: 100%;  
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
            .welcome-form{
                width: 100%;
                margin: 0 auto;
            }
        }
    }

   

    .profile-screen {
        .pwd-change {
            margin-top: 25px;
        }
    }
}

@media only screen and (max-width: 450px) {
    .filter-section {
        .action-field {
            flex-direction: column;
            margin-bottom: 0px;

            .field {
                width: 100%;
                margin-bottom: 10px;
            }
        }
    }

   
    
    .filter-section{
        .drop-down {
            margin-right: 0;
            margin-bottom: 20px;
        }
        .user-dropdown {
            [class*="-control"] {
                width: 100%;
            }
        }
    }
    
}

@media only screen and (max-width: 375px) {
   

}